<template>
  <OnClickOutside @trigger="showDrop = false">
    <div
        class="select-field unselectable"
        :class="{ active: showDrop }"
        @click="!disabled ? showDrop = !showDrop: ''"
    >
      <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
      >
        <path
            d="M15.6 15.63H2.4c-.247 0-.45-.245-.45-.54 0-.296.203-.54.45-.54h13.2c.245 0 .45.244.45.54 0 .295-.205.54-.45.54z"
            fill="#4195FF"
        />
        <path
            d="M13.68 15.455c-.295 0-.54-.204-.54-.45V12.33c0-.246.245-.45.54-.45.295 0 .54.204.54.45v2.676c0 .246-.238.45-.54.45z"
            fill="#4195FF"
        />
        <path
            d="M13.678 12.78a1.77 1.77 0 0 1-1.77-1.77V9.648a1.77 1.77 0 1 1 3.54 0v1.362a1.77 1.77 0 0 1-1.77 1.77zm0-3.996a.87.87 0 0 0-.87.87v1.362a.87.87 0 0 0 1.74 0V9.654a.87.87 0 0 0-.87-.87zM10.38 15.45a.453.453 0 0 1-.45-.45V5.418c0-.954-.402-1.362-1.338-1.362H4.854c-.942 0-1.35.408-1.35 1.362V15c0 .246-.204.45-.45.45a.453.453 0 0 1-.45-.45V5.418c0-1.458.798-2.262 2.25-2.262h3.738c1.446 0 2.238.804 2.238 2.262V15c0 .246-.204.45-.45.45z"
            fill="#4195FF"
        />
        <path
            d="M8.249 7.38h-2.97c-.246 0-.45-.245-.45-.54 0-.295.204-.54.45-.54h2.97c.246 0 .45.245.45.54 0 .295-.204.54-.45.54zM8.249 9.63h-2.97c-.246 0-.45-.245-.45-.54 0-.295.204-.54.45-.54h2.97c.246 0 .45.245.45.54 0 .295-.204.54-.45.54zM6.84 15.45c-.295 0-.54-.205-.54-.45v-2.25c0-.247.245-.45.54-.45.295 0 .54.203.54.45V15c0 .245-.245.45-.54.45z"
            fill="#4195FF"
        />
      </svg>
      <div class="user-name-container ms-1">
        <span class="user-name" v-if="!readonly">{{ getItemName() }}</span>
        <span class="user-name" v-else>Tenants ({{ groupList.length }})</span>
      </div>
      <Icon class="ms-auto" v-if="!disabled" :name="!this.showDrop ? 'arrow_down' : 'arrow_up'" />

    <Transition>
      <div
          v-if="showDrop"
          class="select-box position-absolute bg-white"
          :class="{ active: showDrop }"
          @click.stop
      >
        <div class="select-input">
          <input
              v-if="showDrop"
              ref="search"
              type="text"
              :placeholder="$t('button.search')"
              @input="handleSearch($event.target.value)"
              class="search-bar"
          />
        </div>

        <RecycleScroller class="select-items"
                         :style="{opacity: showScroller}"
                         :key="refreshScroller"
                         :class="{'more-items': showMore}"
                         ref="scroller"
                         id="scroller"
                         :items="!readonly ? filterList : groupList"
                         :item-size="30"
                         key-field="ncm"
        >
          <template #empty>
            <div class="no-results p-2 text-start" v-if="!readonly ? !filterList.length : !groupList.length">
              {{ $t("misc.noResults") }}
            </div>
          </template>
          <template v-slot="{ item }">
            <div
                class="select-item clickable d-flex gap-2 align-content-center unselectable"
                @click="clickItem(item)"
            >
              <template v-if="!readonly">
                <div class="select-svg" v-if="selected === item.ncm">
                  <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                        cx="9"
                        cy="9"
                        r="6"
                        fill="#fff"
                        stroke="#4195FF"
                        stroke-width="2"
                    />
                    <circle
                        cx="9.053"
                        cy="9.053"
                        r="3.722"
                        transform="rotate(2.346 9.053 9.053)"
                        fill="#4195FF"
                    />
                  </svg>
                </div>
                <div class="select-svg" v-else>
                  <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                        cx="9"
                        cy="9"
                        r="6"
                        fill="#fff"
                        stroke="#DBDBDB"
                        stroke-width="2"
                    />
                  </svg>
                </div>
              </template>
              <div class="select-name unselectable" :class="{ active: selected === item.ncm }" :title="item.name">
                {{ item.name }}
              </div>
            </div>
          </template>
        </RecycleScroller>

        <div class="text-more" v-if="!readonly ? filterList.length : groupList.length" @click="showMore = !showMore">
          <span v-if="!showMore">{{ $t("misc.showMore") }}...</span>
          <span v-else>{{ $t("misc.showLess") }}...</span>
        </div>

        <div class="select-actions" v-if="!this.readonly">
          <div class="select-buttons">
            <button @click="showDrop = false">{{ $t("button.cancel") }}</button>
            <button @click="saveItem()">{{ $t("button.save") }}</button>
          </div>
        </div>
      </div>
    </Transition>
  </div>
  </OnClickOutside>
</template>
<script>
import {useAppStore} from "@/store";
import {OnClickOutside} from "@vueuse/components";

export default {
  props: ["modelValue", "options", "readonly", "disabled"],
  update: ["update:modelValue"],
  components: {OnClickOutside},

  setup(){
    const appStore = useAppStore();
    return { appStore }
  },
  data() {
    return {
      refreshScroller: 1,
      showScroller: 1,
      showDrop: false,
      selected: '',
      show: false,
      search: "",
      showMore: false,
      items: []
    };
  },
  watch: {
    async search() {
      this.refreshScroller++;
    },
    showMore() {
      const scrollTop = document.querySelector("#scroller").scrollTop;
      this.showScroller = 0;
      this.refreshScroller++;

      setTimeout(() => {
        if(document.querySelector("#scroller"))
          document.querySelector("#scroller").scrollTop = scrollTop
        this.showScroller = 1;
      }, 10);
    },
    showDrop(value) {
      this.search = "";
      this.showScroller = 1;
      if (value) {
        if(!this.readonly){
          this.selected = this.modelValue;
          this.scrollToSelected();
        }

        this.$nextTick(() => this.$refs.search.focus());
      }
    },
  },
  methods: {
    async handleSearch(value) {
      await this.$lazyCaller('search', value, 150);
    },
    scrollToSelected() {
      if (!this.selected || this.readonly)
        return;

      this.goToItemById(this.selected)
    },
    goToItemById(itemId) {
      const index = this.findItemIndexById(itemId);
      if (index !== -1) {
        setTimeout(() => {
          if (this.$refs.scroller)
            this.$refs.scroller.scrollToItem(index);
        }, 100);
      }
    },
    findItemIndexById(itemId) {
      return this.filterList.findIndex(item => item.ncm === itemId);
    },
    clickItem(item) {
      if (this.readonly) return;
      this.selected = item.ncm;
    },
    getItemName() {
      const itemName = this.options.find((x) => x.ncm === this.modelValue)?.name;
      if (!itemName) {
        this.appStore.setTenant(this.options[0])
      }
      return itemName;
    },
    saveItem() {
      this.$emit("update:modelValue", this.selected);
      this.showDrop = false;
    },
    
  },
  computed:{
    filterList() {
      return this.options.filter((item) => {
        return item.name.toLowerCase().includes(this.search.toLowerCase());
      });
    },
    groupList() {
      return this.options.filter((item) => {
        return item.group_id === this.appStore.getTenantGroup && item.name.toLowerCase().includes(this.search.toLowerCase());
      }) 
    },

  }
};
</script>
<style lang="scss" scoped>
.user-name-container {
  height: 30px;
  overflow: hidden;
  width: 150px;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: 0.5px;
  text-align: left;
  color: #484848;

  &::after {
    content: "";
    height: 100%;
    display: inline-block;
    vertical-align: middle;
  }

  &.tenant-list {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.user-name {
  display: inline-block;
  display: -webkit-inline-box;
  max-height: 100%;
  vertical-align: middle;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
}

.select-field {
  width: 223px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px 0 10px;
  border-radius: 5px;
  border: solid 2px #ededed;
  background-color: #fff;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.5px;
  text-align: left;
  position: relative;
}

.select-box {
  z-index: 10000;
  height: auto;
  box-shadow: 0 1.5px 5.8px 0 rgba(0, 0, 0, 0.17);
  width: 312px;
  border-radius: 5px;
  margin-top: 1px;
  top: 40px;
  right: -2px;
}

.select-field.active {
  background: #ededed;
  border-color: white;
  box-shadow: 0 1.5px 5.8px 0 rgba(0, 0, 0, 0.17);

}

.select-input {
  margin: 1.7px;
}

.select-svg {
  display: flex;
  align-items: center;
}

.select-name {
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.5px;
  color: #5f5f5f;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-wrap: nowrap;
  width: auto;
  &.active {
    font-weight: 600;
  }
}

.options {
  color: #9a9a9a;
}

.select-items {
  width: 312px;
  max-height: 210px;
  overflow-y: auto;

  &.more-items {
    max-height: 420px !important;
  }
}

.no-results {
  height: 35px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  font-size: 14px;
  color: #5f5f5f;
  font-weight: normal;
}

.select-item {
  padding: 4.5px 10px;
  overflow: hidden;
  //width: 233px;
  //text-wrap: nowrap;
}

.select-item:hover {
  background-color: #f4f4f4;
}

.text-more {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.5px;
  text-align: left;
  color: #4195ff;
  margin: 5px 13px;
  cursor: pointer;
}

.select-actions {
  border-top: 2px solid #ededed;
}

.select-buttons {
  display: flex;
  justify-content: flex-end;
  padding: 8.5px 15px;
  gap: 15px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.5px;
  color: #8d8d8d;

  & button:nth-child(2) {
    font-weight: 600;
    color: #4195ff;
  }
}

.search-bar {
  width: 100%;
  border: 2px solid #ededed;
  padding-right: 3px;
  height: 36px;
  background-color: white;
  border-radius: 5px;

  &:focus {
    background-color: white;
  }
}


input {
  outline: none;
  border: 2px solid transparent;
  background: url(@/assets/svg/Icon_search.svg) no-repeat scroll 7px 7px;
  padding-left: 30px;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.2s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

</style>
